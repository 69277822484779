<template>
  <div class="refresh-wrap">
    <i class="refresh el-icon-refresh" v-show="!isLoading" @click="handleRefresh" />
    <i class="el-icon-loading" v-show="isLoading" />
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    }
  },

  methods: {
    handleRefresh() {
      this.$emit("refresh")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "Refresh";
</style>
