<template>
  <div class="department-list main-panel large">
    <h3 class="panel-title">部门列表 <refresh :is-loading="isLoading" @refresh="search" /></h3>
    <el-table :data="list" height="500" v-loading="isLoading">
      <el-table-column align="center" label="名称" prop="name"></el-table-column>
      <el-table-column align="center" label="仓库" prop="wareName"></el-table-column>
      <el-table-column align="center" label="地址" prop="address"></el-table-column>
      <el-table-column align="center" label="电话" prop="phone"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <div class="options">
            <i class="update-icon el-icon-edit" title="修改" @click="openLayer(scope.row)"></i>
            <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="del(scope.row.id)">
              <template #reference>
                <i class="del-icon el-icon-close" title="删除"></i>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <my-layer v-if="Object.keys(layerInfo).length"
              :title="layerInfo.layerTitle"
              :component="layerComponent"
              @handle="layerInfo = {}; search();"
              :layer-info="layerInfo"
              @close="layerInfo = {}" />
  </div>
</template>

<script>
import Refresh from "@/components/Refresh/Refresh";
import MyLayer from "@/components/MyLayer/MyLayer";
import {shallowRef} from "vue";
export default {
  components: {MyLayer, Refresh},
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 50
      },
      list: [],
      isLoading: false,

      layerComponent: undefined,
      layerInfo: {}
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    async search() {
      this.form.currentPage = 1
      this.list = []
      await this.getList()
    },

    async getList() {
      this.isLoading = true
      const res = await this.$api.Base.Department.getList().catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.form.currentPage += 1
        this.list.push(...res.data)
      } else {
        this.$message.error("获取列表失败")
      }
    },

    async openLayer(info = {}) {
      // console.log(info)
      this.layerComponent = shallowRef((await import("@/views/base/department-set/department-set")).default)
      this.layerInfo = {...info, layerTitle: "部门修改"}
    },

    async del(id) {
      this.isLoading = true
      const res = await this.$api.Base.Department.del(id).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success("删除成功")
        this.form.pageSize = this.list.length - 1
        await this.search()
        this.form.pageSize = this.$options.data().form.pageSize
      } else {
        this.$message.error(res?.msg || "删除失败")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "department-list";
</style>
